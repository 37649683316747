import React from "react";
import "./style.scss";

const Footer = () => {
  return (
    <footer className="main-footer">
      Todos os direitos reservados &copy; 2019
    </footer>
  );
};

export default Footer;
