import React, { Component } from "react";
import LogoRisu from "../../images/logo.png";

import HeaderAvatar from "../HeaderAvatar/index";

import { AppContext } from "../../contexts/AppContext";
import "./style.scss";

class Header extends Component {
  static contextType = AppContext;
  render() {
    const { user } = this.context;
    return (
      <header className="main-header">
        <div className="content-left">
          <img src={LogoRisu} alt="Logo Risü" className="logo" />
        </div>
        <div className="content-right">
          {user && <HeaderAvatar user={user} />}
        </div>
      </header>
    );
  }
}

export default Header;
