import React, { Component } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Request, { AvailableRequests } from "../../../util/Requests/request";
import Loading from '../../../components/Loading';

import "./style.scss";

class MySales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: []
    };
  }

  componentDidMount() {
    this.loadPurchases();
  }

  loadPurchases = () => {
    this.setState({
      loading: true
    }, async () => {
      try {
        const response = await Request("GET", AvailableRequests.GET_PURCHASES);
        this.setState({
          data: response && response.data,
          loading: false,
        });
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    })
  }

  render() {
    const { data, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div className="my-sales-container">
        <h1>Minhas vendas</h1>
        <Paper>
          <Table size="small">
            <TableHead><TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Instituição</TableCell>
              <TableCell>Valor da Venda</TableCell>
              <TableCell>Comissão</TableCell>
              <TableCell>Comprador</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {data.map(purchase => {
                return <TableRow key={purchase.uuid}>
                  <TableCell>{purchase.status}</TableCell>
                  <TableCell>{purchase.uuid}</TableCell>
                  <TableCell>{purchase.institution.name}</TableCell>
                  <TableCell>{purchase.amount}</TableCell>
                  <TableCell>{purchase.comission}</TableCell>
                  <TableCell>{purchase.buyer_email}<br />
                    {purchase.phone}</TableCell>
                  <TableCell>{purchase.created_at_format}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
};

export default MySales;
