import React, { Component, Fragment } from "react";
import Request, { AvailableRequests } from "../../../util/Requests/request";
import Loading from "../../../components/Loading/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AppContext } from "../../../contexts/AppContext";

import "./style.scss";

class MyInstitutions extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selected: {},
      institutions: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const selectedData = await Request(
        "GET",
        AvailableRequests.GET_INSTITUTIONS
      );
      const institutions = await Request(
        "GET",
        AvailableRequests.GET_ALL_INSTITUTIONS
      );

      const objSelected = {};
      selectedData.data.map(institution => {
        objSelected[institution.id] = 1;
      });

      this.setState({
        selected: objSelected,
        institutions: institutions.data
      });
    } catch (err) {
      console.log(err);
    }
  };
  addOrRemoveInstitution = async (event, id_institution, institution) => {
    const { setProperty } = this.context;
    const { target } = event;
    target.disabled = true;
    try {
      const result = await Request("POST", AvailableRequests.SET_INSTITUTION, {
        id_institution,
        active: target.checked
      });
      setProperty("message", {
        show: true,
        text: `Dados atualizados com sucesso para ${institution}!`,
        type: "success"
      });
    } catch (err) {
      target.checked = !target.checked;
      setProperty("message", {
        show: true,
        text: `Erro ao atualizar os dados para ${institution}! Estamos atualizando os dados apresentados`,
        type: "error"
      });
    }

    this.loadData();
    target.disabled = false;

    setTimeout(() => {
      setProperty("message", {
        show: false
      });
    }, 5000);
  };

  getHeader = category => {
    return (
      <TableRow>
        <TableCell colspan="3"><strong>{category}</strong></TableCell>
      </TableRow>
    );
  };

  render() {
    const { loading, selected, institutions } = this.state;
    if (loading) {
      return <Loading />;
    }

    let lastCategory = "";
    return (
      <div className="my-institutions-container">
        <h1>Instituições</h1>
        <Paper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Logo</TableCell>
                <TableCell>Instituição</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {institutions.map(institution => {
                let header;
                const category = (institution && institution.institution_category && institution.institution_category.name) || 'Sem categoria';
                if (lastCategory !== category) {
                  lastCategory = category;
                  header = this.getHeader(lastCategory);
                } else {
                  header = null;
                }

                return (
                  <Fragment>
                    {header}
                    <TableRow key={institution.id}>
                      <TableCell>
                        <input
                          type="checkbox"
                          defaultChecked={
                            selected[institution.id] ? "checked" : ""
                          }
                          onChange={event =>
                            this.addOrRemoveInstitution(
                              event,
                              institution.id,
                              institution.name
                            )
                          }
                        />
                      </TableCell>
                        <TableCell>{institution.path && <img src={`https://s3.amazonaws.com/risu-static/instituicoes/logo/${institution.path}.jpg`} height="100" />}</TableCell>
                      <TableCell>{institution.name}</TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default MyInstitutions;
