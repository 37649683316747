import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Header from "../../components/Header";
import Content from "../../components/Content";
import Footer from "../../components/Footer";
import LoginContainer from "../LoginContainer/LoginContainer";
import PrivateApplicationContainer from "../PrivateApplicationContainer/PrivateApplicationContainer";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import AppContextProvider from "../../contexts/AppContext";

import Message from "../../components/Message/index";

import "./AppContainer.scss";

class App extends Component {

  render() {
    return (
      <AppContextProvider>
        <div className="app-container">
          <Message />
          <BrowserRouter>
            <Header />
            <Content>
              <Switch>
                <Route path="/login" component={LoginContainer} />
                <PrivateRoute
                  path="/application"
                  component={PrivateApplicationContainer}
                />
                <Redirect to="/login" />
              </Switch>
            </Content>
            <Footer />
          </BrowserRouter>
        </div>
      </AppContextProvider>
    );
  }
}

export default App;
