import React, { Component } from "react";
import getToken from "../util/Authentication/getToken";
import Request, { AvailableRequests } from "../util/Requests/request";

export const AppContext = React.createContext({});

export const AppContextConsumer = AppContext.Consumer;

export default class AppContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      message: null,
      setProperty: this.setProperty
    };

  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser = async () => {
    const token = getToken();
    if (token) {
      const response = await Request(
        "GET",
        AvailableRequests.AUTHENTICATION_ME
      );

      if (response && response.data) {
        this.setState({
          user: { ...response.data, token }
        });
      }
    }
  };

  setProperty = (field, value) => {
    this.setState(state => ({
      [field]: value
    }));
  };

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider value={this.state}>{children}</AppContext.Provider>
    );
  }
}
