import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import "./style.scss";

class MenuAdmin extends Component {
  render() {
    return (
      <div className="menu-admin">
        <List>
          <ListItem
            button
            key="dashboard"
            component="a"
            href="/application/dashboard"
          >
            <ListItemText primary="Dashboard" />
          </ListItem>
          <Divider />
          <ListItem
            button
            key="my-details"
            component="a"
            href="/application/my-details"
          >
            <ListItemText primary="Meus dados" />
          </ListItem>
          <Divider />
          <ListItem
            button
            key="my-institutions"
            component="a"
            href="/application/my-institutions"
          >
            <ListItemText primary="Instituições" />
          </ListItem>
          <Divider />
          <ListItem
            button
            key="my-sales"
            component="a"
            href="/application/my-sales"
          >
            <ListItemText primary="Vendas" />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default MenuAdmin;
