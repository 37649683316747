import axios from "axios";
import getToken from "../Authentication/getToken";
import config from "../../config/config";

export const AvailableRequests = {
  SIGNIN: "/v1/signed/authentication/signin",
  AUTHENTICATION_ME: "/v1/signed/authentication/me",
  GET_ME: "/v1/signed/authentication/fullme",
  GET_PURCHASES: "/v1/signed/advertiser/getPurchases",
  EDIT_DETAILS: "/v1/signed/advertiser/doUpdateAdvertiser",
  GENERATE_TOKEN: "/v1/signed/advertiser/doGenerateToken",
  GET_INSTITUTIONS: '/v1/signed/advertiser/getInstitutions',
  GET_ALL_INSTITUTIONS: '/v1/signed/institution/getAllInstitutions',
  SET_INSTITUTION: '/v1/signed/advertiser/setInstitution'
};

export default (method, path, data) => {
  const sessionToken = getToken();
  let header = "";
  if (sessionToken) {
    header = `Bearer ${sessionToken}`;
  }
  axios.defaults.headers.common.Authorization = header;
  const axiosConfig = {
    method,
    url: `${config.API_URL}${path}`,
    data
  };

  return axios(axiosConfig);
};
