import React, { Component } from "react";
import classnames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import Request, { AvailableRequests } from "../../util/Requests/request";
import setToken from "../../util/Authentication/setToken";
import { withRouter } from "react-router";
import { AppContext } from "../../contexts/AppContext";

import "./Login.scss";

class Login extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      fields: { email: "", password: "" },
      error: null
    };
  }

  formSubmit = async event => {
    const { setProperty } = this.context;
    event.preventDefault();
    const {
      fields: { email, password }
    } = this.state;

    if (!email || !password) return;

    this.setState({
      error: null
    });

    try {
      const response = await Request("POST", AvailableRequests.SIGNIN, {
        email,
        password
      });

      if (response && response.data && response.data.token) {
        setToken(response.data.token);
        setProperty("user", response.data);
        const { from } = this.props.location.state || {
          from: { pathname: "/application" }
        };

        this.props.history.push(from.pathname);
      }
    } catch (err) {
      const { response: { data = { message: "Erro ao obter os dados" } } = {} } = err;
      this.setState({
        error: data
      });
    }
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState(state => {
      return { fields: { ...state.fields, [name]: value } };
    });
  };

  render() {
    const {
      fields: { email, password },
      error
    } = this.state;
    const { className } = this.props;
    return (
      <div className={classnames("login", className)}>
        <h1>Login</h1>
        <form
          className="form"
          noValidate
          method="POST"
          onSubmit={event => this.formSubmit(event)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel htmlFor="login-email">Email address</InputLabel>
                <Input
                  id="login-email"
                  type="email"
                  name="email"
                  value={email}
                  aria-describedby="login-email"
                  onChange={this.handleInputChange}
                />
                <FormHelperText id="login-email">
                  Please type your email.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel htmlFor="login-password">Password</InputLabel>
                <Input
                  id="login-password"
                  type="password"
                  name="password"
                  value={password}
                  aria-describedby="login-password"
                  onChange={this.handleInputChange}
                />
                <FormHelperText id="login-password">
                  Please type your passsword.
                </FormHelperText>
              </FormControl>
            </Grid>
            
            {error &&
              <Grid item xs={12} className="error-message">
                {error.message}
              </Grid>
            }
            
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="primary-button"
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}
export default withRouter(Login);
