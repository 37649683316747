const variables = {
  development: {
    API_URL: "https://api.risu.com.br"
  },
  production: {
    API_URL: "https://api.risu.com.br"
  }
};

const config = variables[process.env.NODE_ENV || "development"];

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
