import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import "./style.scss";

class HeaderAvatar extends Component {
  static contextType = AppContext;

  logout = () => {
    const { setProperty } = this.context;
    window.localStorage.clear();
    setProperty("user", null);
  };

  render() {
    const { user } = this.context;

    return (
      <div className="header-avatar">
        <span className="empresa">
          <strong>Empresa:</strong> {user.name}
        </span>
        <span className="cnpj">
          <strong>CNPJ:</strong> {user.cnpj}
        </span>
        <Link to="/" className="logout" onClick={this.logout}>
          Sair
        </Link>
      </div>
    );
  }
}

export default HeaderAvatar;
