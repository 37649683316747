import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import Snackbars from "../Snackbars/index";

const Message = () => {
  const context = useContext(AppContext);
  const { message, setProperty } = context;
  if (message && message.show && message.text) {
    return (
      <Snackbars
        type={message.type}
        message={message.text}
        onClose={() => {
          setProperty("message", { show: false });
        }}
      />
    );
  }
  return null;
};

export default Message;
