import React, { Component, Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Request, { AvailableRequests } from "../../../util/Requests/request";
import Loading from "../../../components/Loading/index";
import { AppContext } from "../../../contexts/AppContext";

import Typography from "@material-ui/core/Typography";
import "./style.scss";

class MyDetails extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      details: null,
      fields: {
        name: "",
        cnpj: "",
        phone: "",
        zipcode: "",
        street: "",
        city: "",
        state: "",
        email: "",
        neighborhood: "",
        number: "",
        complement: ""
      }
    };
  }

  componentDidMount() {
    this.loadDetails();
  }

  formSubmit = async event => {
    event.preventDefault();
    const { setProperty } = this.context;
    const {
      fields: {
        name,
        cnpj,
        phone,
        zipcode,
        street,
        city,
        state,
        email,
        neighborhood,
        number,
        complement
      }
    } = this.state;

    try {
      const response = await Request("POST", AvailableRequests.EDIT_DETAILS, {
        advertiser: {
          name,
          cnpj,
          phone,
          zipcode,
          street,
          city,
          state,
          email,
          neighborhood,
          number,
          complement
        }
      });

      if (response && response.data) {
        setProperty("message", {
          show: true,
          text: "Dados atualizados com sucesso!",
          type: "success"
        });
      }
    } catch (err) {
      let text;
      if (err && err.response && err.response.data && err.response.data.error) {
        text = err.response.data.error;
      } else {
        console.log("err: ", err);
        text = "Erro não identificado. Estamos providenciando a correção";
      }
      setProperty("message", {
        show: true,
        text: text,
        type: "error"
      });
    }
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState(state => {
      return {
        fields: { ...state.fields, [name]: value },
        message: { show: false, message: "", type: "" }
      };
    });
  };

  loadDetails = () => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        const response = await Request("GET", AvailableRequests.GET_ME);
        if (response && response.data) {
          const {
            name,
            cnpj,
            key,
            phone,
            zipcode,
            street,
            city,
            state,
            email,
            neighborhood,
            number,
            complement
          } = response.data;
          this.setState({
            fields: {
              name: name ? name : "",
              cnpj: cnpj ? cnpj : "",
              key: key
                ? key
                : "",
              phone: phone ? phone : "",
              zipcode: zipcode ? zipcode : "",
              street: street ? street : "",
              city: city ? city : "",
              state: state ? state : "",
              email: email ? email : "",
              neighborhood: neighborhood ? neighborhood : "",
              number: number ? number : "",
              complement: complement ? complement : ""
            }
          });
        }

        this.setState({
          isLoading: false
        });
      }
    );
  };

  generateToken = async (event) => {
    event.preventDefault();
    const response = await Request("GET", AvailableRequests.GENERATE_TOKEN);
    if (response && response.data) {
      this.setState(state => {
        return { fields: { ...state.fields, key: response.data.key } }
      });
    }
  };

  render() {
    const { fields, isLoading } = this.state;

    if (isLoading) {
      return <Loading />;
    }
    if (!fields) {
      return null;
    }
    const {
      name,
      cnpj,
      key,
      phone,
      zipcode,
      street,
      city,
      state,
      email,
      neighborhood,
      number,
      complement
    } = fields;
    return (
      <div className="my-details-container">
        <h1>Meus dados</h1>

        <form
          className="form"
          noValidate
          method="POST"
          onSubmit={event => this.formSubmit(event)}
        >
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Token de Autorização:
                  </Typography>
                  <Typography color="textSecondary">
                    Necessário para requisições via API
                  </Typography>
                  {key &&
                    <Fragment>
                      <Typography variant="body2" component="p" className="wrap-word">
                        {key}
                      </Typography>
                    </Fragment>
                  }
                  <button onClick={this.generateToken}>Gerar novo token</button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-name">Nome</InputLabel>
                <Input
                  id="details-name"
                  type="name"
                  name="name"
                  fullWidth
                  value={name}
                  aria-describedby="details-name"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-cnpj">CNPJ</InputLabel>
                <Input
                  id="details-cnpj"
                  type="cnpj"
                  name="cnpj"
                  fullWidth
                  value={cnpj}
                  aria-describedby="details-cnpj"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-phone">Telefone</InputLabel>
                <Input
                  id="details-phone"
                  type="phone"
                  name="phone"
                  fullWidth
                  value={phone}
                  aria-describedby="details-phone"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-email">E-mail</InputLabel>
                <Input
                  id="details-email"
                  type="text"
                  fullWidth
                  disabled
                  readOnly
                  value={email}
                  aria-describedby="details-email"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-zipcode">CEP</InputLabel>
                <Input
                  id="details-zipcode"
                  type="zipcode"
                  name="zipcode"
                  fullWidth
                  value={zipcode}
                  aria-describedby="details-zipcode"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-street">Logradouro</InputLabel>
                <Input
                  id="details-street"
                  type="street"
                  name="street"
                  fullWidth
                  value={street}
                  aria-describedby="details-street"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-number">Número</InputLabel>
                <Input
                  id="details-number"
                  type="number"
                  name="number"
                  fullWidth
                  value={number}
                  aria-describedby="details-number"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-complement">
                  Complemento
                </InputLabel>
                <Input
                  id="details-complement"
                  type="complement"
                  name="complement"
                  fullWidth
                  value={complement}
                  aria-describedby="details-complement"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-city">Cidade</InputLabel>
                <Input
                  id="details-city"
                  type="city"
                  name="city"
                  fullWidth
                  value={city}
                  aria-describedby="details-city"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-state">Estado</InputLabel>
                <Input
                  id="details-state"
                  type="state"
                  name="state"
                  fullWidth
                  value={state}
                  aria-describedby="details-state"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="details-neighborhood">Bairro</InputLabel>
                <Input
                  id="details-neighborhood"
                  type="neighborhood"
                  name="neighborhood"
                  fullWidth
                  value={neighborhood}
                  aria-describedby="details-neighborhood"
                  onChange={this.handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="primary-button"
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default MyDetails;
