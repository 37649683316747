import React from "react";
import Login from "../../components/Login";

import "./LoginContainer.scss";

function LoginContainer() {
  return (
    <div className="login-container">
      <Login className="centralized box-shadow" />
    </div>
  );
}

export default LoginContainer;
