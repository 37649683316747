import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MenuAdmin from "../../components/Menu/MenuAdmin";

import Dashboard from "./Dashboard";
import MyDetails from "./MyDetails";
import MyInstitutions from "./MyInstitutions";
import MySales from "./MySales";

import "./PrivateApplicationContainer.scss";

const PrivateApplicationContainer = props => {
  const { match } = props;
  return (
    <div className="private-app-container">
      <div className="content-left">
        <MenuAdmin />
      </div>
      <div className="content-right">
        <Switch>
          <Route path={`${match.path}/dashboard`} component={Dashboard} />
          <Route path={`${match.path}/my-details`} component={MyDetails} />
          <Route
            path={`${match.path}/my-institutions`}
            component={MyInstitutions}
          />
          <Route path={`${match.path}/my-sales`} component={MySales} />
          <Redirect to={`${match.path}/dashboard`} />
        </Switch>
      </div>
    </div>
  );
};

export default PrivateApplicationContainer;
